import React, { useEffect, useState } from "react"

import { styles } from "../styles";

import EmailForm from "./EmailForm";
// import ChatEngine from "./ChatEngine";
import MessagePage from "../../components/ChatExtension/MessagePage";
import { logout, setOnlineUser, setPeer, setSocketConnection, setUser } from "../../redux/userSlice";
import { useDispatch, useSelector } from 'react-redux'
import io from 'socket.io-client'
import axios from "axios";
import Peer from 'peerjs';

const SupportWindow = props => {
    // const [user, setUser1] = useState(props.user._id ? props.user : null )
    const user = useSelector(state => state.user)
    const [chat, setChat] = useState(user?.email?.length > 0)

    const dispatch = useDispatch()

    // useEffect(()=>{
    //     //   fetchUserDetails()
    //     setChat(user?.email?.length > 0);
    //     },[user])

    // console.log('user--------------------------------------------------hbhbh',user)
  // const fetchUserDetails = async()=>{
  //   try {
  //       const URL = `${process.env.REACT_APP_BACKEND_URL}/api/user-details`
  //       const token = localStorage.getItem('support_token');

  //       if(token){
  //       const response = await axios.get(URL, {
  //         headers: {
  //           Authorization: `Bearer ${token}`
  //         },
  //         withCredentials: true
  //       });
  //       setUser1(response.data.data)
  //       dispatch(setUser(response.data.data))

  //       if(response.data.data.logout){
  //           dispatch(logout())
  //           // navigate("/email")
  //       }
  //       console.log("current user Details",response)
  //     }
  //   } catch (error) {
  //       console.log("error",error)
  //       // navigate("/email")
  //   }
  // }

  // useEffect(()=>{
  //   fetchUserDetails()
  // },[])
    
    useEffect(()=>{
        if(chat){
        const socketConnection = io(process.env.REACT_APP_BACKEND_URL,{
          auth : {
            token : localStorage.getItem('support_token')
          },
        })
    
        socketConnection.on('onlineUser',(data)=>{
          // console.log("nnnnnnn", data)
          dispatch(setOnlineUser(data))
        })
    
        dispatch(setSocketConnection(socketConnection))
    
        return ()=>{
          socketConnection.disconnect()
        }
    }
      },[chat])


    // useEffect(() => {
    //   const newPeer = new Peer(undefined, {
    //     // host: '/', port: '3001'
    //     path: '/',
    //     secure: true,
    //   });
  
    //   console.log('newPeer', newPeer )
      
    //   dispatch(setPeer(newPeer))
    // }, [dispatch]);

    return (
        <div 
            className='transition-5'
            style={{
                ...styles.supportWindow,
                ...{ opacity: props.visible ? '1' : '0' },
                zIndex: 999,
            }}
        >
            <EmailForm 
                // visible={user?.chatTO?.length === 0 }
                visible= {!chat}
                setUser={user =>  setChat(true)} 
            />

            {/* <ChatEngine 
                visible={user !== null || chat !== null}
                user={user} 
                chat={chat} 
            /> */}

            {/* <div>
                get in
            </div> */}

            {chat && <MessagePage   userId="66ce399b09826914d9bb85a2" /> }
        </div>
    )
}

export default SupportWindow;
