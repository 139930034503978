export const treeData = [
  {
    value: 'Addis Ababa Coordination',
    title: 'Addis Ababa Coordination',
    children: [
      {
        value: 'North Addis Ababa Region',
        title: 'North Addis Ababa Region',
        children: [
          {
            value: 'North AA CSC No.1',
            title: 'North AA CSC No.1',
          },
          {
            value: 'North AA CSC No.2',
            title: 'North AA CSC No.2',
          },
          {
            value: 'North AA CSC No.3',
            title: 'North AA CSC No.3',
          },
          {
            value: 'North AA CSC No.4',
            title: 'North AA CSC No.4',
          },
          {
            value: 'North AA CSC No.5',
            title: 'North AA CSC No.5',
          },
        ],
      },
      {
        value: 'East Addis Ababa Region',
        title: 'East Addis Ababa Region',
        children: [
          {
            value: 'East AA CSC No.1',
            title: 'East AA CSC No.1',
          },
          {
            value: 'East AA CSC No.2',
            title: 'East AA CSC No.2',
          },
          {
            value: 'East AA CSC No.3',
            title: 'East AA CSC No.3',
          },
          {
            value: 'East AA CSC No.4',
            title: 'East AA CSC No.4',
          },
          {
            value: 'East AA CSC No.5',
            title: 'East AA CSC No.5',
          },
          {
            value: 'East AA CSC No.6',
            title: 'East AA CSC No.6',
          },
          {
            value: 'East AA CSC No.7',
            title: 'East AA CSC No.7',
          },
          // {
          //   value: 'East AA CSC No.8',
          //   title: 'East AA CSC No.8',
          // },
          {
            value: 'East AA CSC No.9',
            title: 'East AA CSC No.9',
          },
          {
            value: 'East AA CSC NO.12 /LEMI/',
            title: 'East AA CSC NO.12 /LEMI/',
          },
        ],
      },
      {
        value: 'West Addis Ababa Region',
        title: 'West Addis Ababa Region',
        children: [
          {
            value: 'West AA CSC No.1',
            title: 'West AA CSC No.1',
          },
          {
            value: 'West AA CSC No.2',
            title: 'West AA CSC No.2',
          },
          {
            value: 'West AA CSC No.3',
            title: 'West AA CSC No.3',
          },
          {
            value: 'West AA CSC No.4',
            title: 'West AA CSC No.4',
          },
          // {
          //   value: 'West AA CSC No.5',
          //   title: 'West AA CSC No.5',
          // },
           {
            value: 'West AA CSC No.6',
            title: 'West AA CSC No.6',
          },
          {
            value: 'West AA CSC No.7',
            title: 'West AA CSC No.7',
          },
          {
            value: 'West AA CSC No.8',
            title: 'West AA CSC No.8',
          },
        ],
      },
      {
        value: 'South Addis Ababa Region',
        title: 'South Addis Ababa Region',
        children: [
          // {
          //   value: 'South AA CSC No.1',
          //   title: 'South AA CSC No.1',
          // },
          // {
          //   value: 'South AA CSC No.2',
          //   title: 'South AA CSC No.2',
          // },
          // {
          //   value: 'South AA CSC No.3',
          //   title: 'South AA CSC No.3',
          // },
          // {
          //   value: 'South AA CSC No.4',
          //   title: 'South AA CSC No.4',
          // },
          // {
          //   value: 'South AA CSC No.5',
          //   title: 'South AA CSC No.5',
          // },
          {
            value: 'South AA CSC No.6',
            title: 'South AA CSC No.6',
          },
          {
            value: 'South AA CSC No.7',
            title: 'South AA CSC No.7',
          },
          {
            value: 'South AA CSC No.8',
            title: 'South AA CSC No.8',
          },
          {
            value: 'South AA CSC No.9',
            title: 'South AA CSC No.9',
          },
          {
            value: 'South AA CSC NO.10 (LEBU)',
            title: 'South AA CSC No.10',
          },
          {
            value: 'TULUDIMTU CUSTOMER CSC',
            title: 'TULUDIMTU CUSTOMER CSC',
          },
          {
            value: 'South AA BULBULA CSC',
            title: 'South AA BULBULA CSC',
          },
        ],
      },
    ],
  },
  {
    value: 'Oromia Coordination',
    title: 'Oromia Coordination',
    children: [
      {
        "value": "ADAMA REGION",
        "title": "ADAMA REGION",
        "children": [
          {
            "value": "ADAMA CSC. NO.1",
            "title": "ADAMA CSC. NO.1"
          },
          {
            "value": "ADAMA CSC. NO.2",
            "title": "ADAMA CSC. NO.2"
          },
          {
            "value": "ADAMA CSC. NO.3",
            "title": "ADAMA CSC. NO.3"
          },
          {
            "value": "ADAMA CSC. NO.4",
            "title": "ADAMA CSC. NO.4"
          },
          {
            "value": "ABOMESA",
            "title": "ABOMESA"
          },
          {
            "value": "ADAMITULU",
            "title": "ADAMITULU"
          },
          {
            "value": "BEKOJI",
            "title": "BEKOJI"
          },
          {
            "value": "BULBULA",
            "title": "BULBULA"
          },
          {
            "value": "DERA",
            "title": "DERA"
          },
          {
            "value": "KERSA",
            "title": "KERSA"
          },
          {
            "value": "AWASH MELKASA",
            "title": "AWASH MELKASA"
          },
          {
            "value": "SAGURE",
            "title": "SAGURE"
          },
          {
            "value": "WOLENCHITY",
            "title": "WOLENCHITY"
          },
          {
            "value": "WONJI",
            "title": "WONJI"
          },
          {
            "value": "ASELLA NO.1",
            "title": "ASELLA NO.1"
          },
          {
            "value": "METEHARA",
            "title": "METEHARA"
          },
          {
            "value": "MODJO",
            "title": "MODJO"
          },
          {
            "value": "ZEWAY",
            "title": "ZEWAY"
          },
          {
            "value": "GOBESSA",
            "title": "GOBESSA"
          },
          {
            "value": "ARSIROBE",
            "title": "ARSIROBE"
          },
          {
            "value": "ALEMTENA",
            "title": "ALEMTENA"
          },
          {
            "value": "ETEYA",
            "title": "ETEYA"
          },
          {
            "value": "CHOLE",
            "title": "CHOLE"
          },
          {
            "value": "OGOLCHO",
            "title": "OGOLCHO"
          },
          {
            "value": "DIKSIS",
            "title": "DIKSIS"
          },
          {
            "value": "HURUTA",
            "title": "HURUTA"
          },
          {
            "value": "MEKI",
            "title": "MEKI"
          },
          {
            "value": "ASELLA NO.2",
            "title": "ASELLA NO.2"
          },
          {
            "value": "BELE",
            "title": "BELE"
          },
          {
            "value": "ARBOYE",
            "title": "ARBOYE"
          }
        ]
      },
      {
        "value": "FINFINE REGION",
        "title": "FINFINE REGION",
        "children": [
          {
            "value": "SENDAFA CSC",
            "title": "SENDAFA CSC"
          },
          {
            "value": "SHENO CSC",
            "title": "SHENO CSC"
          },
          {
            "value": "MUKETURI CSC",
            "title": "MUKETURI CSC"
          },
          {
            "value": "FINFINE DST FICHE CSC",
            "title": "FINFINE DST FICHE CSC"
          },
          {
            "value": "GEBREGURACHA CSC",
            "title": "GEBREGURACHA CSC"
          },
          {
            "value": "FINFINE DST EJERE CSC",
            "title": "FINFINE DST EJERE CSC"
          },
          {
            "value": "GUNDOMESKEL CSC",
            "title": "GUNDOMESKEL CSC"
          },
          {
            "value": "LEGETAFO CSC",
            "title": "LEGETAFO CSC"
          },
          {
            "value": "GOHATSION CSC",
            "title": "GOHATSION CSC"
          },
          {
            "value": "BURAYU CSC",
            "title": "BURAYU CSC"
          },
          {
            "value": "TERE CSC",
            "title": "TERE CSC"
          },
          {
            "value": "CHANCO CSC",
            "title": "CHANCO CSC"
          },
          {
            "value": "HOLETA CSC",
            "title": "HOLETA CSC"
          },
          {
            "value": "MUGER CSC",
            "title": "MUGER CSC"
          },
          {
            "value": "SULULTA CSC",
            "title": "SULULTA CSC"
          },
          {
            "value": "TATEKE CSC",
            "title": "TATEKE CSC"
          },
          {
            "value": "DUKEM CSC",
            "title": "DUKEM CSC"
          },
          {
            "value": "BISHOFTU CSC NO.1",
            "title": "BISHOFTU CSC NO.1"
          },
          {
            "value": "BISHOFTU CSC NO.2",
            "title": "BISHOFTU CSC NO.2"
          },
          {
            "value": "CHEFE DONSA CSC",
            "title": "CHEFE DONSA CSC"
          },
          {
            "value": "ADULALA CSC",
            "title": "ADULALA CSC"
          },
          {
            "value": "GELAN CSC",
            "title": "GELAN CSC"
          },
          {
            "value": "SEBETA CSC",
            "title": "SEBETA CSC"
          },
          {
            "value": "ALEMGENA CSC",
            "title": "ALEMGENA CSC"
          },
          {
            "value": "FURI CSC",
            "title": "FURI CSC"
          },
          {
            "value": "ANFO MED CSC",
            "title": "ANFO MED CSC"
          },
          {
            "value": "LEMEN CSC",
            "title": "LEMEN CSC"
          },
          {
            "value": "OROM.CONDO CSC",
            "title": "OROM.CONDO CSC"
          },
          {
            "value": "DST SHINO CSC",
            "title": "DST SHINO CSC"
          }
        ]
      },
      {
        "value": "SHASHEMENE REGION",
        "title": "SHASHEMENE REGION",
        "children": [
          {
            "value": "SHASHEMANE NO -1 CSC",
            "title": "SHASHEMANE NO -1 CSC"
          },
          {
            "value": "SHASHEMANE NO -2 CSC",
            "title": "SHASHEMANE NO -2 CSC"
          },
          {
            "value": "BORE CSC",
            "title": "BORE CSC"
          },
          {
            "value": "H/MARIAM CSC",
            "title": "H/MARIAM CSC"
          },
          {
            "value": "KOFELE CSC",
            "title": "KOFELE CSC"
          },
          {
            "value": "N/BORENA CSC",
            "title": "N/BORENA CSC"
          },
          {
            "value": "SHAKISO CSC",
            "title": "SHAKISO CSC"
          },
          {
            "value": "YABELO CSC",
            "title": "YABELO CSC"
          },
          {
            "value": "ARSI NEGELE CSC",
            "title": "ARSI NEGELE CSC"
          },
          {
            "value": "K/ MENGIST CSC",
            "title": "K/ MENGIST CSC"
          },
          {
            "value": "MOYALE CSC",
            "title": "MOYALE CSC"
          },
          {
            "value": "SOLOMO CSC",
            "title": "SOLOMO CSC"
          },
          {
            "value": "TORE CSC",
            "title": "TORE CSC"
          },
          {
            "value": "KOKOSA CSC",
            "title": "KOKOSA CSC"
          },
          {
            "value": "BISHAN GURRACHA CSC",
            "title": "BISHAN GURRACHA CSC"
          },
          {
            "value": "GUANGUA CSC",
            "title": "GUANGUA CSC"
          },
          {
            "value": "KERCHA CSC",
            "title": "KERCHA CSC"
          },
          {
            "value": "ADJE CSC",
            "title": "ADJE CSC"
          },
          {
            "value": "MEGA CSC",
            "title": "MEGA CSC"
          },
          {
            "value": "GOLJOTA CSC",
            "title": "GOLJOTA CSC"
          },
          {
            "value": "HAREKELO CSC",
            "title": "HAREKELO CSC"
          },
          {
            "value": "BIDIRE CSC",
            "title": "BIDIRE CSC"
          },
          {
            "value": "DIMTU CSC",
            "title": "DIMTU CSC"
          },
          {
            "value": "MELKA SODA CSC",
            "title": "MELKA SODA CSC"
          },
          {
            "value": "ASASA CSC",
            "title": "ASASA CSC"
          }
        ]
      },
      {
        "value": "CHIRO REGION",
        "title": "CHIRO REGION",
        "children": [
          {
            "value": "CHIRO CSC",
            "title": "CHIRO CSC"
          },
          {
            "value": "BABILE CSC",
            "title": "BABILE CSC"
          },
          {
            "value": "BEDESSA CSC",
            "title": "BEDESSA CSC"
          },
          {
            "value": "BISIDIMO CSC",
            "title": "BISIDIMO CSC"
          },
          {
            "value": "KOMBOLCHA CSC",
            "title": "KOMBOLCHA CSC"
          },
          {
            "value": "GELEMSO CSC",
            "title": "GELEMSO CSC"
          },
          {
            "value": "GURSUM CSC",
            "title": "GURSUM CSC"
          },
          {
            "value": "HIRNA CSC",
            "title": "HIRNA CSC"
          },
          {
            "value": "KARAMILE CSC",
            "title": "KARAMILE CSC"
          },
          {
            "value": "KERSSA CSC",
            "title": "KERSSA CSC"
          },
          {
            "value": "DEDER CSC",
            "title": "DEDER CSC"
          },
          {
            "value": "HAROMAYA CSC",
            "title": "HAROMAYA CSC"
          },
          {
            "value": "GIRAWA CSC",
            "title": "GIRAWA CSC"
          },
          {
            "value": "MECHARA CSC",
            "title": "MECHARA CSC"
          },
          {
            "value": "MIESO CSC",
            "title": "MIESO CSC"
          },
          {
            "value": "CHINAKSEN CSC",
            "title": "CHINAKSEN CSC"
          },
          {
            "value": "FEDIS CSC",
            "title": "FEDIS CSC"
          },
          {
            "value": "AWODAY CSC",
            "title": "AWODAY CSC"
          }
        ]
      },
      {
        "value": "JIMMA REGION",
        "title": "JIMMA REGION",
        "children": [
          {
            "value": "JIMMA CSC NO.1",
            "title": "JIMMA CSC NO.1"
          },
          {
            "value": "JIMMA CSC NO.2",
            "titlCSCe": "JIMMA CSC NO.2"
          },
          {
            "value": "ASENDIBO CSC",
            "title": "ASENDIBO CSC"
          },
          {
            "value": "LEMU CSC",
            "title": "LEMU CSC"
          },
          {
            "value": "TOBA CSC",
            "title": "TOBA CSC"
          },
          {
            "value": "SOKORO CSC",
            "title": "SOKORO CSC"
          },
          {
            "value": "AGARO CSC",
            "title": "AGARO CSC"
          },
          {
            "value": "DUMTU CSC",
            "title": "DUMTU CSC"
          },
          {
            "value": "GATIRA CSC",
            "title": "GATIRA CSC"
          },
          {
            "value": "GERA CSC",
            "title": "GERA CSC"
          },
          {
            "value": "DEDO CSC",
            "title": "DEDO CSC"
          },
          {
            "value": "SHEBI CSC",
            "title": "SHEBI CSC"
          },
          {
            "value": "AGRO CSC 2",
            "title": "AGRO CSC 2"
          },
          {
            "value": "DEMBI CSC",
            "title": "DEMBI CSC"
          }
        ]
      },
      {
        "value": "NEKEMT REGION",
        "title": "NEKEMT REGION",
        "children": [
          {
            "value": "NEKEMT NO1",
            "title": "NEKEMT NO1"
          },
          {
            "value": "FINCHA",
            "title": "FINCHA"
          },
          {
            "value": "SHAMBU",
            "title": "SHAMBU"
          },
          {
            "value": "ALIBO",
            "title": "ALIBO"
          },
          {
            "value": "MECHARA",
            "title": "MECHARA"
          },
          {
            "value": "MUGI",
            "title": "MUGI"
          },
          {
            "value": "ROB-GEBYA",
            "title": "ROB-GEBYA"
          },
          {
            "value": "DEMBI DOLLO",
            "title": "DEMBI DOLLO"
          },
          {
            "value": "BEGI",
            "title": "BEGI"
          },
          {
            "value": "GULISO",
            "title": "GULISO"
          },
          {
            "value": "MENDI",
            "title": "MENDI"
          },
          {
            "value": "NEJO",
            "title": "NEJO"
          },
          {
            "value": "GIMBI",
            "title": "GIMBI"
          },
          {
            "value": "HAROSEBU",
            "title": "HAROSEBU"
          },
          {
            "value": "NOLE",
            "title": "NOLE"
          },
          {
            "value": "GIDA AYANA",
            "title": "GIDA AYANA"
          },
          {
            "value": "SIRE",
            "title": "SIRE"
          },
          {
            "value": "ANGER GUTIN",
            "title": "ANGER GUTIN"
          },
          {
            "value": "KIRAMU",
            "title": "KIRAMU"
          },
          {
            "value": "ARJO A.",
            "title": "ARJO A."
          },
          {
            "value": "DADU",
            "title": "DADU"
          },
          {
            "value": "SASIGA",
            "title": "SASIGA"
          },
          {
            "value": "UKE",
            "title": "UKE"
          },
          {
            "value": "ARJO GUDITU",
            "title": "ARJO GUDITU"
          },
          {
            "value": "NUNU KUMBA",
            "title": "NUNU KUMBA"
          },
          {
            "value": "OBORA",
            "title": "OBORA"
          },
          {
            "value": "NEKEMT NO2",
            "title": "NEKEMT NO2"
          },
          {
            "value": "GENJI",
            "title": "GENJI"
          },
          {
            "value": "H. LIMU",
            "title": "H. LIMU"
          },
          {
            "value": "KAKE",
            "title": "KAKE"
          }
        ]
      },
      {
        "value": "AMBO REGION",
        "title": "AMBO REGION",
        "children": [
          {
            "value": "AMBO CSC",
            "title": "AMBO CSC"
          },
          {
            "value": "TULU BOLO CSC",
            "title": "TULU BOLO CSC"
          },
          {
            "value": "WOLISO CSC",
            "title": "WOLISO CSC"
          },
          {
            "value": "BANTU CSC",
            "title": "BANTU CSC"
          },
          {
            "value": "AMEYA CSC",
            "title": "AMEYA CSC"
          },
          {
            "value": "ADDIS ALEM CSC",
            "title": "ADDIS ALEM CSC"
          },
          {
            "value": "GINCHI CSC",
            "title": "GINCHI CSC"
          },
          {
            "value": "KACHESE CSC",
            "title": "KACHESE CSC"
          },
          {
            "value": "GUDER CSC",
            "title": "GUDER CSC"
          },
          {
            "value": "HORMATE CSC",
            "title": "HORMATE CSC"
          },
          {
            "value": "GEDO CSC",
            "title": "GEDO CSC"
          },
          {
            "value": "AJIJO CSC",
            "title": "AJIJO CSC"
          },
          {
            "value": "BAKO CSC",
            "title": "BAKO CSC"
          },
          {
            "value": "WAYO CSC",
            "title": "WAYO CSC"
          },
          {
            "value": "JELDU CSC",
            "title": "JELDU CSC"
          },
          {
            "value": "SEYO CSC",
            "title": "SEYO CSC"
          },
          {
            "value": "SADANSODE CSC",
            "title": "SADANSODE CSC"
          },
          {
            "value": "ABUNA CSC",
            "title": "ABUNA CSC"
          },
          {
            "value": "SHENEN  CSC",
            "title": "SHENEN  CSC"
          },
          {
            "value": "BILO  CSC",
            "title": "BILO  CSC"
          },
          {
            "value": "DIRE ENCHINI CSC",
            "title": "DIRE ENCHINI CSC"
          },
          {
            "value": "BUSA CSC",
            "title": "BUSA CSC"
          },
          {
            "value": "SILKAMBA CSC",
            "title": "SILKAMBA CSC"
          }
        ]
      },
      {
        "value": "BALE ROBE REGION",
        "title": "BALE ROBE REGION",
        "children": [
          {
            "value": "BALE ROBE CSC",
            "title": "BALE ROBE CSC"
          },
          {
            "value": "BALE GOBA CSC",
            "title": "BALE GOBA CSC"
          },
          {
            "value": "DODOLA CSC",
            "title": "DODOLA CSC"
          },
          {
            "value": "GINIRE CSC",
            "title": "GINIRE CSC"
          },
          {
            "value": "ADABA CSC",
            "title": "ADABA CSC"
          },
          {
            "value": "AGARFA CSC",
            "title": "AGARFA CSC"
          },
          {
            "value": "GASERA CSC",
            "title": "GASERA CSC"
          },
          {
            "value": "GORO CSC",
            "title": "GORO CSC"
          },
          {
            "value": "DELOMENA CSC",
            "title": "DELOMENA CSC"
          },
          {
            "value": "HARA DUMELE CSC",
            "title": "HARA DUMELE CSC"
          },
          {
            "value": "RAYITU/CHERECHER CSC",
            "title": "RAYITU/CHERECHER CSC"
          },
          {
            "value": "DINSHO CSC",
            "title": "DINSHO CSC"
          },
          {
            "value": "WERKA CSC",
            "title": "WERKA CSC"
          },
          {
            "value": "JARA CSC",
            "title": "JARA CSC"
          }
        ]
      },
      {
        "value": "METU REGION",
        "title": "METU REGION",
        "children": [
          {
            "value": "METU CSC",
            "title": "METU CSC"
          },
          {
            "value": "GORE CSC",
            "title": "GORE CSC"
          },
          {
            "value": "SUPI CSC",
            "title": "SUPI CSC"
          },
          {
            "value": "YAYU CSC",
            "title": "YAYU CSC"
          },
          {
            "value": "BURE CSC",
            "title": "BURE CSC"
          },
          {
            "value": "DURENI CSC",
            "title": "DURENI CSC"
          },
          {
            "value": "DIREMU CSC",
            "title": "DIREMU CSC"
          },
          {
            "value": "DEGA CSC",
            "title": "DEGA CSC"
          },
          {
            "value": "CHORA CSC",
            "title": "CHORA CSC"
          },
          {
            "value": "BEDELAE CSC",
            "title": "BEDELAE CSC"
          },
          {
            "value": "CHEWAKA CSC",
            "title": "CHEWAKA CSC"
          },
          {
            "value": "HURMU CSC",
            "title": "HURMU CSC"
          },
          {
            "value": "NOPA CSC",
            "title": "NOPA CSC"
          },
          {
            "value": "BECHO CSC",
            "title": "BECHO CSC"
          },
          {
            "value": "SELE NONO CSC",
            "title": "SELE NONO CSC"
          },
          {
            "value": "LALO CSC",
            "title": "LALO CSC"
          }
        ]
      },
    ]
  },


  {
    value: 'Amhara Coordination',
    title: 'Amhara Coordination',
    children: [
      { 
        "value": "BAHIRDAR  REGION",
        "title": "BAHIRDAR  REGION",
        "children": [
          {
            "value": "BAHIR DAR 1 CSC",
            "title": "BAHIR DAR 1 CSC"
          },
          {
            "value": "BAHIR DAR 2 CSC",
            "title": "BAHIR DAR 2 CSC"
          },
          {
            "value": "BAHERDAR NO.3(ABAYEMADO)CSC",
            "title": "BAHERDAR NO.3(ABAYEMADO)CSC"
          },
          {
            "value": "ADET CSC",
            "title": "ADET CSC"
          },
          {
            "value": "CHAGNI CSC",
            "title": "CHAGNI CSC"
          },
          {
            "value": "DURBETIE CSC",
            "title": "DURBETIE CSC"
          },
          {
            "value": "KOSSOBER CSC",
            "title": "KOSSOBER CSC"
          },
          {
            "value": "MERAWI CSC",
            "title": "MERAWI CSC"
          },
          {
            "value": "WORETA CSC",
            "title": "WORETA CSC"
          },
          {
            "value": "DANGLIA CSC",
            "title": "DANGLIA CSC"
          },
          {
            "value": "DEBERE TABOR",
            "title": "DEBERE TABOR"
          },
          {
            "value": "EBNAT CSC",
            "title": "EBNAT CSC"
          },
          {
            "value": "JAWI CSC",
            "title": "JAWI CSC"
          },
          {
            "value": "ESTIE CSC",
            "title": "ESTIE CSC"
          },
          {
            "value": "ADDIS ZEMEN CSC",
            "title": "ADDIS ZEMEN CSC"
          },
          {
            "value": "WEGEDA CSC",
            "title": "WEGEDA CSC"
          },
          {
            "value": "GEMEJABET CSC",
            "title": "GEMEJABET CSC"
          },
          {
            "value": "GISHABAY CSC",
            "title": "GISHABAY CSC"
          },
          {
            "value": "ADDISKEDAM CSC",
            "title": "ADDISKEDAM CSC"
          },
          {
            "value": "LIBEN CSC",
            "title": "LIBEN CSC"
          },
          {
            "value": "KILAJ CSC",
            "title": "KILAJ CSC"
          },
          {
            "value": "AMBESAME CSC",
            "title": "AMBESAME CSC"
          },
          {
            "value": "NEFAS MEWUCHA CSC",
            "title": "NEFAS MEWUCHA CSC"
          },
          {
            "value": "DEBRETABOR 2",
            "title": "DEBRETABOR 2"
          },
          {
            "value": "KIMIRDINGAY",
            "title": "KIMIRDINGAY"
          },
          {
            "value": "GONJI CSC",
            "title": "GONJI CSC"
          },
          {
            "value": "TACH GAYINT (ARB GEBEYA) CSC",
            "title": "TACH GAYINT (ARB GEBEYA) CSC"
          },
          {
            "value": "ANDABET CSC",
            "title": "ANDABET CSC"
          },
          {
            "value": "TILILI CSC",
            "title": "TILILI CSC"
          }
        ]
      },
      {
        "value": "DESSIE  REGION",
        "title": "DESSIE  REGION",
        "children": [
          {
            "value": "DESSIE 1 CSC",
            "title": "DESSIE 1 CSC"
          },
          {
            "value": "DESSIE 2 CSC",
            "title": "DESSIE 2 CSC"
          },
          {
            "value": "BATI CSC",
            "title": "BATI CSC"
          },
          {
            "value": "AKESTA CSC",
            "title": "AKESTA CSC"
          },
          {
            "value": "HAIK CSC",
            "title": "HAIK CSC"
          },
          {
            "value": "MEKANESELAM CSC",
            "title": "MEKANESELAM CSC"
          },
          {
            "value": "TENTA CSC",
            "title": "TENTA CSC"
          },
          {
            "value": "WERILU CSC",
            "title": "WERILU CSC"
          },
          {
            "value": "KEMISSE CSC",
            "title": "KEMISSE CSC"
          },
          {
            "value": "KOMBOLCHA NO.1 CSC",
            "title": "KOMBOLCHA NO.1 CSC"
          },
          {
            "value": "KOMBOLCHA NO.2 CSC",
            "title": "KOMBOLCHA NO.2 CSC"
          },
          {
            "value": "SAINT AJIBAR CSC",
            "title": "SAINT AJIBAR CSC"
          },
          {
            "value": "KUTABER CSC",
            "title": "KUTABER CSC"
          },
          {
            "value": "WEGDI CSC",
            "title": "WEGDI CSC"
          },
          {
            "value": "WUCHALE CSC",
            "title": "WUCHALE CSC"
          },
          {
            "value": "KELALA CSC",
            "title": "KELALA CSC"
          },
          {
            "value": "WEGL TENA CSC",
            "title": "WEGL TENA CSC"
          },
          {
            "value": "ALBKO CSC",
            "title": "ALBKO CSC"
          },
          {
            "value": "BISTIMA CSC",
            "title": "BISTIMA CSC"
          },
          {
            "value": "CHEFA ROBIT CSC",
            "title": "CHEFA ROBIT CSC"
          },
          {
            "value": "HARBU CSC",
            "title": "HARBU CSC"
          },
          {
            "value": "JAMMA CSC",
            "title": "JAMMA CSC"
          },
          {
            "value": "MASHA CSC",
            "title": "MASHA CSC"
          },
          {
            "value": "DENSA CSC",
            "title": "DENSA CSC"
          },
          {
            "value": "MEKOY CSC",
            "title": "MEKOY CSC"
          },
          {
            "value": "LEGEHIDA CSC",
            "title": "LEGEHIDA CSC"
          },
          {
            "value": "DESSIE 3 CSC",
            "title": "DESSIE 3 CSC"
          }
        ]
      },
      {
        "value": "GONDER REGION",
        "title": "GONDER REGION",
        "children": [
          {
            "value": "GONDER 1 CSC",
            "title": "GONDER 1 CSC"
          },
          {
            "value": "GONDER 2 CSC",
            "title": "GONDER 2 CSC"
          },
          {
            "value": "GONDER NO.3 CSC",
            "title": "GONDER NO.3 CSC"
          },
          {
            "value": "CHILGA CSC",
            "title": "CHILGA CSC"
          },
          {
            "value": "DABAT CSC",
            "title": "DABAT CSC"
          },
          {
            "value": "DEBARK CSC",
            "title": "DEBARK CSC"
          },
          {
            "value": "KOLLADIBA CSC",
            "title": "KOLLADIBA CSC"
          },
          {
            "value": "METEMA CSC",
            "title": "METEMA CSC"
          },
          {
            "value": "SANJA CSC",
            "title": "SANJA CSC"
          },
          {
            "value": "MAKSEGNET CSC",
            "title": "MAKSEGNET CSC"
          },
          {
            "value": "DELGI CSC",
            "title": "DELGI CSC"
          },
          {
            "value": "ADARIKAYE CSC",
            "title": "ADARIKAYE CSC"
          },
          {
            "value": "AREBAYA CSC",
            "title": "AREBAYA CSC"
          },
          {
            "value": "GELAJO CSC",
            "title": "GELAJO CSC"
          },
          {
            "value": "GUHASSA CSC",
            "title": "GUHASSA CSC"
          },
          {
            "value": "MEKANE BIRHAN CSC",
            "title": "MEKANE BIRHAN CSC"
          },
          {
            "value": "SHAWERA CSC",
            "title": "SHAWERA CSC"
          },
          {
            "value": "TEKELDINGAYE CSC",
            "title": "TEKELDINGAYE CSC"
          },
          {
            "value": "ABIDRAFI CSC",
            "title": "ABIDRAFI CSC"
          },
          {
            "value": "AMBA GIORGIES CSC",
            "title": "AMBA GIORGIES CSC"
          },
          {
            "value": "METEMA YOHANS CSC",
            "title": "METEMA YOHANS CSC"
          },
          {
            "value": "GONDER D-CHUAHIT CSC",
            "title": "GONDER D-CHUAHIT CSC"
          },
          {
            "value": "KIRAKIR CSC",
            "title": "KIRAKIR CSC"
          }
        ]
      },
      {
        "value": "D/BIRHAN REGION",
        "title": "D/BIRHAN REGION",
        "children": [
          {
            "value": "DEBERE BIRHAN NO 1 CSC",
            "title": "DEBERE BIRHAN NO 1 CSC"
          },
          {
            "value": "DEBERE BIRHAN NO 2 CSC",
            "title": "DEBERE BIRHAN NO 2 CSC"
          },
          {
            "value": "ENEWARI CSC",
            "title": "ENEWARI CSC"
          },
          {
            "value": "ANKOBER CSC",
            "title": "ANKOBER CSC"
          },
          {
            "value": "ALEMKETEMA CSC",
            "title": "ALEMKETEMA CSC"
          },
          {
            "value": "LEMI CSC",
            "title": "LEMI CSC"
          },
          {
            "value": "HAGEREMARIM CSC",
            "title": "HAGEREMARIM CSC"
          },
          {
            "value": "ARERTI CSC",
            "title": "ARERTI CSC"
          },
          {
            "value": "ATAYE CSC",
            "title": "ATAYE CSC"
          },
          {
            "value": "DEBRESINA CSC",
            "title": "DEBRESINA CSC"
          },
          {
            "value": "MEHALMEDA CSC",
            "title": "MEHALMEDACSC"
          },
          {
            "value": "MOLALLE CSC",
            "title": "MOLALLE CSC"
          },
          {
            "value": "SHOAROBIT CSC",
            "title": "SHOAROBITCSC"
          },
          {
            "value": "GISHERABL CSC",
            "title": "GISHERABL CSC"
          },
          {
            "value": "METIHIBILA CSC",
            "title": "METIHIBILA CSC"
          },
          {
            "value": "SELADNGAY CSC",
            "title": "SELADNGAY CSC"
          },
          {
            "value": "GINAGER CSC",
            "title": "GINAGER CSC"
          },
          {
            "value": "DENBA CSC",
            "title": "DENBA CSC"
          },
          {
            "value": "CHACHA CSC",
            "title": "CHACHA CSC"
          },
          {
            "value": "MIDA CSC",
            "title": "MIDA CSC"
          }
        ]
      },
      {
        "value": "D/MARKOS REGION",
        "title": "D/MARKOS REGION",
        "children": [
          {
            "value": "DEBEREMARKOS NO. 1 CSC",
            "title": "DEBEREMARKOS NO. 1 CSC"
          },
          {
            "value": "DEBEREMARKOS NO. 2 CSC",
            "title": "DEBEREMARKOS NO. 2 CSC"
          },
          {
            "value": "AMANUEL CSC",
            "title": "AMANUEL CSC"
          },
          {
            "value": "BICHENA CSC",
            "title": "BICHENA CSC"
          },
          {
            "value": "DEJEN CSC",
            "title": "DEJEN CSC"
          },
          {
            "value": "GINDEWOYIN CSC",
            "title": "GINDEWOYIN CSC"
          },
          {
            "value": "KUYE CSC",
            "title": "KUYE CSC"
          },
          {
            "value": "MOTA CSC",
            "title": "MOTA CSC"
          },
          {
            "value": "GOHATSIYON CSC",
            "title": "GOHATSIYON CSC"
          },
          {
            "value": "LUMMAME CSC",
            "title": "LUMMAME CSC"
          },
          {
            "value": "METOLMARIAMCSC",
            "title": "METOLMARIAMCSC"
          },
          {
            "value": "YEJUBE CSC",
            "title": "YEJUBE CSC"
          },
          {
            "value": "BURIE CSC",
            "title": "BURIE CSC"
          },
          {
            "value": "F/SELAM CSC",
            "title": "F/SELAM CSC"
          },
          {
            "value": "SHENDI CSC",
            "title": "SHENDI CSC"
          },
          {
            "value": "DENBECHA CSC",
            "title": "DENBECHA CSC"
          },
          {
            "value": "FERES BET CSC",
            "title": "FERES BET CSC"
          },
          {
            "value": "DEBRELIAS CSC",
            "title": "DEBRELIAS CSC"
          },
          {
            "value": "GEBEZEMARIAM CSC",
            "title": "GEBEZEMARIAM CSC"
          },
          {
            "value": "DEBREWORK CSC",
            "title": "DEBREWORK CSC"
          },
          {
            "value": "YEDUHA CSC",
            "title": "YEDUHA CSC"
          }
        ]
      },
      {
        "value": "WOLDIYA REGION",
        "title": "WOLDIYA REGION",
        "children": [
          {
            "value": "WOLEDIYA CSC",
            "title": "WOLEDIYA CSC"
          },
          {
            "value": "AMEDEWORK CSC",
            "title": "AMEDEWORK CSC"
          },
          {
            "value": "GASHENA CSC",
            "title": "GASHENA CSC"
          },
          {
            "value": "LALIBELA CSC",
            "title": "LALIBELA CSC"
          },
          {
            "value": "KOBO CSC",
            "title": "KOBO CSC"
          },
          {
            "value": "MERSA CSC",
            "title": "MERSA CSC"
          },
          {
            "value": "SEKOTA CSC",
            "title": "SEKOTA CSC"
          },
          {
            "value": "NURAK CSC",
            "title": "NURAK CSC"
          },
          {
            "value": "AYENABUGUNA CSC",
            "title": "AYENABUGUNA CSC"
          },
          {
            "value": "DAWENT CSC",
            "title": "DAWENT CSC"
          },
          {
            "value": "ZEQUALA CSC",
            "title": "ZEQUALA CSC"
          },
          {
            "value": "ROBIT CSC",
            "title": "ROBIT CSC"
          },
          {
            "value": "FILAQIT CSC",
            "title": "FILAQIT CSC"
          },
          {
            "value": "MUJA CSC",
            "title": "MUJA CSC"
          },
          {
            "value": "WOLDIYA CSC 2",
            "title": "WOLDIYA CSC 2"
          }
        ]
      },
    ],
  },











  {
    value: 'SNNPR Coordination',
    title: 'SNNPR Coordination',
    children: [
      {
        "value": "ARBAMINCH REGION",
        "title": "ARBAMINCH REGION",
        "children": [
          {
            "value": "ARBAMINCH NO. 1 CSC",
            "title": "ARBAMINCH NO. 1 CSC"
          },
          {
            "value": "ARBAMINCH NO. 2 CSC",
            "title": "ARBAMINCH NO. 2 CSC"
          },
          {
            "value": "SAWELA CSC",
            "title": "SAWELA CSC"
          },
          {
            "value": "CHENCHA CSC",
            "title": "CHENCHA CSC"
          },
          {
            "value": "GRESE BONKE CSC",
            "title": "GRESE BONKECSC"
          },
          {
            "value": "BASKITO CSC",
            "title": "BASKITO CSC"
          },
          {
            "value": "SEGEN CSC",
            "title": "SEGEN CSC"
          },
          {
            "value": "JINKA CSC",
            "title": "JINKA CSC"
          },
          {
            "value": "DIMEKA CSC",
            "title": "DIMEKA CSC"
          },
          {
            "value": "GIDOLE/DERASHE CSC",
            "title": "GIDOLE/DERASHE CSC"
          },
          {
            "value": "MIERABABAY CSC",
            "title": "MIERABABAY CSC"
          },
          {
            "value": "BETO CSC",
            "title": "BETO CSC"
          },
          {
            "value": "KONSSO CSC",
            "title": "KONSSO CSC"
          },
          {
            "value": "SELAMBER CSC",
            "title": "SELAMBER CSC"
          },
          {
            "value": "AMAROKELE CSC",
            "title": "AMAROKELE CSC"
          },
          {
            "value": "BURJI CSC",
            "title": "BURJI CSC"
          },
          {
            "value": "KENBA CSC",
            "title": "KENBA CSC"
          },
          {
            "value": "DITA CSC",
            "title": "DITA CSC"
          }
        ]
      },
      {
        "value": "HASSANA REGION",
        "title": "HASSANA REGION",
        "children": [
          {
            "value": "HOSSAIENA CSC-1",
            "title": "HOSSAIENA CSC-1"
          },
          {
            "value": "WOLKITE",
            "title": "WOLKITE"
          },
          {
            "value": "BUTAJIRA",
            "title": "BUTAJIRA"
          },
          {
            "value": "WORABE",
            "title": "WORABE"
          },
          {
            "value": "BUIE",
            "title": "BUIE"
          },
          {
            "value": "EMDIBIR",
            "title": "EMDIBIR"
          },
          {
            "value": "GUNCHIRE",
            "title": "GUNCHIRE"
          },
          {
            "value": "AGENA",
            "title": "AGENA"
          },
          {
            "value": "DARGE",
            "title": "DARGE"
          },
          {
            "value": "DALOCHA",
            "title": "DALOCHA"
          },
          {
            "value": "TORA",
            "title": "TORA"
          },
          {
            "value": "DURAME",
            "title": "DURAME"
          },
          {
            "value": "GIMBICHU",
            "title": "GIMBICHU"
          },
          {
            "value": "MUDULA",
            "title": "MUDULA"
          },
          {
            "value": "DOYO GENA",
            "title": "DOYO GENA"
          },
          {
            "value": "ANGACHA",
            "title": "ANGACHA"
          },
          {
            "value": "LERA",
            "title": "LERA"
          },
          {
            "value": "MORSITO",
            "title": "MORSITO"
          },
          {
            "value": "SAJA",
            "title": "SAJA"
          },
          {
            "value": "ALEM GEBEYA",
            "title": "ALEM GEBEYA"
          },
          {
            "value": "SHINSHICHO",
            "title": "SHINSHICHO"
          },
          {
            "value": "HOSSAIENA CSC-2",
            "title": "HOSSAIENA CSC-2"
          },
          {
            "value": "HAWARIAT",
            "title": "HAWARIAT"
          },
          {
            "value": "GOMBORA",
            "title": "GOMBORA"
          },
          {
            "value": "AREKIT",
            "title": "AREKIT"
          },
          {
            "value": "FONKO",
            "title": "FONKO"
          },
          {
            "value": "DINKULA",
            "title": "DINKULA"
          },
          {
            "value": "KOSHE",
            "title": "KOSHE"
          }
        ]
      },
      {
        "value": "W/SODO REGION",
        "title": "W/SODO REGION",
        "children": [
          {
            "value": "WELAYITA SODO NO.1",
            "title": "WELAYITA SODO NO.1"
          },
          {
            "value": "WELAYITA SODO NO.2",
            "title": "WELAYITA SODO NO.2"
          },
          {
            "value": "AREKA",
            "title": "AREKA"
          },
          {
            "value": "BEDESA",
            "title": "BEDESA"
          },
          {
            "value": "BELE GUNINO",
            "title": "BELE GUNINO"
          },
          {
            "value": "BODITI",
            "title": "BODITI"
          },
          {
            "value": "GESUPA",
            "title": "GESUPA"
          },
          {
            "value": "HADERO",
            "title": "HADERO"
          },
          {
            "value": "HUMBO",
            "title": "HUMBO"
          },
          {
            "value": "SHONE",
            "title": "SHONE"
          },
          {
            "value": "ALABA",
            "title": "ALABA"
          },
          {
            "value": "BULE",
            "title": "BULE"
          },
          {
            "value": "WENAGO",
            "title": "WENAGO"
          },
          {
            "value": "YIRGACHEFE",
            "title": "YIRGACHEFE"
          },
          {
            "value": "DILLA",
            "title": "DILLA"
          },
          {
            "value": "CHELELEKTU",
            "title": "CHELELEKTU"
          },
          {
            "value": "GEDEB",
            "title": "GEDEB"
          },
          {
            "value": "BOREDA",
            "title": "BOREDA"
          }
        ]
      },
    ],
  },



  {
    value: 'Sumale Coordination',
    title: 'Sumale Coordination',
    children: [
      {
        "value": "JIJIGA REGION",
        "title": "JIJIGA REGION",
        "children": [
          {
            "value": "AYESHA CSC",
            "title": "AYESHA CSC"
          },
          {
            "value": "ERER CSC",
            "title": "ERER CSC"
          },
          {
            "value": "KULEN CSC",
            "title": "KULEN CSC"
          },
          {
            "value": "AWARE CSC",
            "title": "AWARE CSC"
          },
          {
            "value": "SHEKOSH CSC",
            "title": "SHEKOSH CSC"
          },
          {
            "value": "FIKE CSC",
            "title": "FIKE CSC"
          },
          {
            "value": "AWBERE CSC",
            "title": "AWBERE CSC"
          },
          {
            "value": "KEBRIBEYAHI CSC",
            "title": "KEBRIBEYAHI CSC"
          },
          {
            "value": "DEGEHABUR CSC",
            "title": "DEGEHABUR CSC"
          },
          {
            "value": "JIJIGA CSC NO.1",
            "title": "JIJIGA CSC NO.1"
          },
          {
            "value": "JIJIGA CSC NO.2",
            "title": "JIJIGA CSC NO.2"
          },
          {
            "value": "FILETU CSC",
            "title": "FILETU CSC"
          },
          {
            "value": "FAFEN CSC",
            "title": "FAFEN CSC"
          },
          {
            "value": "TOGOWUCHALE CSC",
            "title": "TOGOWUCHALE CSC"
          }
        ]
      },
      {
        "value": "GODE REGION",
        "title": "GODE REGION",
        "children": [
          {
            "value": "GODE CSC",
            "title": "GODE CSC"
          },
          {
            "value": "DOLOODO CSC",
            "title": "DOLOODO CSC"
          },
          {
            "value": "HARGELE CSC",
            "title": "HARGELE CSC"
          },
          {
            "value": "SHILABO CSC",
            "title": "SHILABO CSC"
          },
          {
            "value": "GASHAMO CSC",
            "title": "GASHAMO CSC"
          },
          {
            "value": "BOHE CSC",
            "title": "BOHE CSC"
          },
          {
            "value": "WARDER CSC",
            "title": "WARDER CSC"
          },
          {
            "value": "KEBRIDEHAR CSC",
            "title": "KEBRIDEHAR CSC"
          },
          {
            "value": "DENEN CSC",
            "title": "DENEN CSC"
          },
          {
            "value": "EMI CSC",
            "title": "EMI CSC"
          }
        ]
      },
],
},




{
  value: 'Tigray Coordination',
  title: 'Tigray Coordination',
  children: [
    {
      "value": "MEKELE REGION",
      "title": "MEKELE REGION",
      "children": [
        {
          "value": "MEKELLE S.C.1",
          "title": "MEKELLE S.C.1"
        },
        {
          "value": "MEKELLE S.C.2",
          "title": "MEKELLE S.C.2"
        },
        {
          "value": "MEKELLE S.C.4 (INDUSTRY PARK)",
          "title": "MEKELLE S.C.4 (INDUSTRY PARK)"
        },
        {
          "value": "ADIGUDOM",
          "title": "ADIGUDOM"
        },
        {
          "value": "ADISHIHU",
          "title": "ADISHIHU"
        },
        {
          "value": "ATSIBI",
          "title": "ATSIBI"
        },
        {
          "value": "FIREWEINI",
          "title": "FIREWEINI"
        },
        {
          "value": "HAWZEN",
          "title": "HAWZEN"
        },
        {
          "value": "KOREM",
          "title": "KOREM"
        },
        {
          "value": "MEKONI",
          "title": "MEKONI"
        },
        {
          "value": "YECHILA",
          "title": "YECHILA"
        },
        {
          "value": "ZALANBESA",
          "title": "ZALANBESA"
        },
        {
          "value": "ABI-ADI",
          "title": "ABI-ADI"
        },
        {
          "value": "ALAMATA",
          "title": "ALAMATA"
        },
        {
          "value": "MAICHEW",
          "title": "MAICHEW"
        },
        {
          "value": "KUIHA",
          "title": "KUIHA"
        },
        {
          "value": "WUKRO",
          "title": "WUKRO"
        },
        {
          "value": "ADIGRAT",
          "title": "ADIGRAT"
        },
        {
          "value": "EDAGA HAMUS",
          "title": "EDAGA HAMUS"
        },
        {
          "value": "SAMRE",
          "title": "SAMRE"
        },
        {
          "value": "HAGERESELAM",
          "title": "HAGERESELAM"
        },
        {
          "value": "DOHAN",
          "title": "DOHAN"
        },
        {
          "value": "BIZET",
          "title": "BIZET"
        },
        {
          "value": "WAJA",
          "title": "WAJA"
        }
      ]
    },
    {
      "value": "SHIRE REGION",
      "title": "SHIRE REGION",
      "children": [
        {
          "value": "SHIRE CSC",
          "title": "SHIRE CSC"
        },
        {
          "value": "ENDABAGUNA  CSC",
          "title": "ENDABAGUNA  CSC"
        },
        {
          "value": "ENTICHO CSC",
          "title": "ENTICHO CSC"
        },
        {
          "value": "MAY-TSEBRI CSC",
          "title": "MAY-TSEBRI CSC"
        },
        {
          "value": "SHIRE CSC 1",
          "title": "SHIRE CSC"
        },
        {
          "value": "RAMA CSC",
          "title": "RAMA CSC"
        },
        {
          "value": "SELEKLEKA CSC",
          "title": "SELEKLEKA CSC"
        },
        {
          "value": "SHERARO CSC",
          "title": "SHERARO CSC"
        },
        {
          "value": "WUKROMARAY CSC",
          "title": "WUKROMARAY CSC"
        },
        {
          "value": "HUMERA CSC",
          "title": "HUMERA CSC"
        },
        {
          "value": "ADWA CSC",
          "title": "ADWA CSC"
        },
        {
          "value": "AXUM CSC",
          "title": "AXUM CSC"
        },
        {
          "value": "E.ARBI CSC",
          "title": "E.ARBI CSC"
        },
        {
          "value": "DANSHA CSC",
          "title": "DANSHA CSC"
        },
        {
          "value": "ADIDAERO CSC",
          "title": "ADIDAERO CSC"
        },
        {
          "value": "SEMEMA CSC",
          "title": "SEMEMA CSC"
        },
        {
          "value": "MAYHANSE CSC",
          "title": "MAYHANSE CSC"
        },
        {
          "value": "NEBELET CSC",
          "title": "NEBELET CSC"
        },
        {
          "value": "BAEKER CSC",
          "title": "BAEKER CSC"
        },
        {
          "value": "ADI-REMETS CSC",
          "title": "ADI-REMETS CSC"
        },
        {
          "value": "MAY-GABA CSC",
          "title": "MAY-GABA CSC"
        },
        {
          "value": "ADIHAGERAY CSC",
          "title": "ADIHAGERAY CSC"
        }
      ]
    },
],
},


{
  "value": "Sidama Region",
  "title": "Sidama Region",
  "children": [
    {
      "value": "HAWASSA NO.1 CSC",
      "title": "HAWASSA NO.1 CSC"
    },
    {
      "value": "HAWASSA NO.2 CSC",
      "title": "HAWASSA NO.2 CSC"
    },
    {
      "value": "HAWASSA NO.3 CSC",
      "title": "HAWASSA NO.3 CSC"
    },
    {
      "value": "HAWASSA NO. 4 (INDUSTRY)",
      "title": "HAWASSA NO. 4 (INDUSTRY)"
    },
    {
      "value": "ALETA WONDO CSC",
      "title": "ALETA WONDO CSC"
    },
    {
      "value": "DAYE CSC",
      "title": "DAYE CSC"
    },
    {
      "value": "H/SELAME CSC",
      "title": "H/SELAME CSC"
    },
    {
      "value": "YIRGALME CSC",
      "title": "YIRGALME CSC"
    },
    {
      "value": "YIRBA CSC",
      "title": "YIRBA CSC"
    },
    {
      "value": "SHEBEDINO/LAKU CSC",
      "title": "SHEBEDINO/LAKU CSC"
    },
    {
      "value": "ARBEGONA CSC",
      "title": "ARBEGONA CSC"
    },
    {
      "value": "CHUKO CSC",
      "title": "CHUKO CSC"
    },
    {
      "value": "GIRJA CSC",
      "title": "GIRJA CSC"
    },
    {
      "value": "KEBADO CSC",
      "title": "KEBADO CSC"
    },
    {
      "value": "WENDOGENET CSC",
      "title": "WENDOGENET CSC"
    }
  ]
},


{ value: 'Afar Region',
  title: 'Afar Region',
  children:  [
    {
      "value": "SEMERA CSC",
      "title": "SEMERA CSC"
    },
    {
      "value": "MELKA WERER CSC",
      "title": "MELKA WERER CSC"
    },
    {
      "value": "AWASH 7 KILLO CSC",
      "title": "AWASH 7 KILLO CSC"
    },
    {
      "value": "TELALAK CSC",
      "title": "TELALAK CSC"
    },
    {
      "value": "DECHETO CSC",
      "title": "DECHETO CSC"
    },
    {
      "value": "LOGIA CSC",
      "title": "LOGIA CSC"
    },
    {
      "value": "ASAYTA CSC",
      "title": "ASAYTA CSC"
    },
    {
      "value": "DUBTI CSC",
      "title": "DUBTI CSC"
    },
    {
      "value": "MILE CSC",
      "title": "MILE CSC"
    },
    {
      "value": "AFDERA CSC",
      "title": "AFDERA CSC"
    },
    {
      "value": "CHIFRA CSC",
      "title": "CHIFRA CSC"
    },
    {
      "value": "ABALA CSC",
      "title": "ABALA CSC"
    },
    {
      "value": "GEWANE CSC",
      "title": "GEWANE CSC"
    },
    {
      "value": "SUNULITA CSC",
      "title": "SUNULITA CSC"
    },
    {
      "value": "KELEWAN CSC",
      "title": "KELEWAN CSC"
    },
    {
      "value": "BERHALE CSC",
      "title": "BERHALE CSC"
    },
    {
      "value": "KUNEBA CSC",
      "title": "KUNEBA CSC"
    },
    {
      "value": "ELIWUHA CSC",
      "title": "ELIWUHA CSC"
    }
  ]
},

{
  
  value: 'Dire Dawa Region',
  title: 'Dire Dawa Region',
  children: [
    {
      "value": "DIRE DAWA 1",
      "title": "DIRE DAWA 1"
    },
    {
      "value": "DIRE DAWA 2",
      "title": "DIRE DAWA 2"
    },
    {
      "value": "MELKA CSC",
      "title": "MELKA CSC"
    },
    {
      "value": "G.M CSC",
      "title": "G.M CSC"
    },
    {
      "value": "INDUSTRY CSC",
      "title": "INDUSTRY CSC"
    }
  ],
},
{
  value: 'Gambella Region',
  title: 'Gambella Region',
  children: [
    {
      "value": "GAMBELLA CSC",
      "title": "GAMBELLA CSC"
    },
    {
      "value": "ETANG CSC",
      "title": "ETANG CSC"
    },
    {
      "value": "ABOBO CSC",
      "title": "ABOBO CSC"
    },
    {
      "value": "FUGNIDO CSC",
      "title": "FUGNIDO CSC"
    },
    {
      "value": "DIMMA CSC",
      "title": "DIMMA CSC"
    },
    {
      "value": "BARO MADO CSC",
      "title": "BARO MADO CSC"
    },
    {
      "value": "LARE CSC",
      "title": "LARE CSC"
    },
    {
      "value": "KUMI CSC",
      "title": "KUMI CSC"
    },
    {
      "value": "METTI CSC",
      "title": "METTI CSC"
    }
  ],
},
{
  value: 'Harer Region',
  title: 'Harer Region',
  children: [
    {
      "value": "HARARE NO.1 CSC",
      "title": "HARARE NO.1 CSC"
    },
    {
      "value": "HARARE NO.2 CSC",
      "title": "HARARE NO.2 CSC"
    },
    {
      "value": "DERETEYARA CSC",
      "title": "DERETEYARA CSC"
    },
    {
      "value": "E.WELDEYA CSC",
      "title": "E.WELDEYA CSC"
    },
    {
      "value": "SOFI CSC",
      "title": "SOFI CSC"
    }
  ]
},

  
{
  "value": "South West Region",
  "title": "South West Region",
  "children": [
    {
      "value": "BONGA CSC",
      "title": "BONGA CSC"
    },
    {
      "value": "MASHA CSC",
      "title": "MASHA CSC"
    },
    {
      "value": "MIZAN CSC",
      "title": "MIZAN CSC"
    },
    {
      "value": "TEPPI CSC",
      "title": "TEPPI CSC"
    },
    {
      "value": "DAKA CSC",
      "title": "DAKA CSC"
    },
    {
      "value": "ODA CSC",
      "title": "ODA CSC"
    },
    {
      "value": "CHENA CSC",
      "title": "CHENA CSC"
    },
    {
      "value": "BIFTU CSC",
      "title": "BIFTU CSC"
    },
    {
      "value": "DEBRE-WORK CSC",
      "title": "DEBRE-WORK CSC"
    },
    {
      "value": "SHEBENCH CSC",
      "title": "SHEBENCH CSC"
    },
    {
      "value": "AMEYA CSC",
      "title": "AMEYA CSC"
    },
    {
      "value": "CHIRI CSC",
      "title": "CHIRI CSC"
    },
    {
      "value": "ADIYOKAKA CSC",
      "title": "ADIYOKAKA CSC"
    },
    {
      "value": "GIMBO CSC",
      "title": "GIMBO CSC"
    },
    {
      "value": "JEMU CSC",
      "title": "JEMU CSC"
    },
    {
      "value": "GESA CSC",
      "title": "GESA CSC"
    },
    {
      "value": "TERCHA CSC",
      "title": "TERCHA CSC"
    },
    {
      "value": "TOCHA CSC",
      "title": "TOCHA CSC"
    }
  ]
},

{
  value: 'Benishangul-Gumuz Region',
  title: "Benishangul-Gumuz Region",
  children: [
        {
          "value": "ASSOSA CSC",
          "title": "ASSOSA CSC"
        },
        {
          "value": "MENGE CSC",
          "title": "MENGE CSC"
        },
        {
          "value": "KAMASH CSC",
          "title": "KAMASH CSC"
        },
        {
          "value": "ODDA CSC",
          "title": "ODDA CSC"
        },
        {
          "value": "BAMBASI CSC",
          "title": "BAMBASI CSC"
        },
        {
          "value": "KUMURUK CSC",
          "title": "KUMURUK CSC"
        },
        {
          "value": "TONGO CSC",
          "title": "TONGO CSC"
        },
        {
          "value": "PAWI CSC",
          "title": "PAWI CSC"
        },
        {
          "value": "SOGE CSC",
          "title": "SOGE CSC"
        },
        {
          "value": "D/ZEYIT CSC",
          "title": "D/ZEYIT CSC"
        },
        {
          "value": "MANKUSH CSC",
          "title": "MANKUSH CSC"
        },
        {
          "value": "BULLEN CSC",
          "title": "BULLEN CSC"
        }

      ]
},

{
  value: 'Head Office',
  title: 'Head Office',
},
{
  value: 'Projects Management Portfolio',
  title: 'Projects Management Portfolio',
}

];


