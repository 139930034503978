import React, { useEffect, useRef, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { logout, setCall, setOnlineUser, setPeer, setSocketConnection, setUser } from './redux/userSlice'
import toast from 'react-hot-toast';
import { io } from 'socket.io-client';
import Peer from 'peerjs';
import axios from 'axios';

const SocketClient = () => {
    const socketConnection = useSelector(state => state?.user?.socketConnection)
    const call = useSelector(state => state?.user?.call)
    const dispatch = useDispatch()

    const [user, setUser1] = useState(null )

    const fetchUserDetails = async()=>{
        try {
            const URL = `${process.env.REACT_APP_BACKEND_URL}/api/user-details`
            const token = localStorage.getItem('support_token');
    
            if(token){
            const response = await axios.get(URL, {
              headers: {
                Authorization: `Bearer ${token}`
              },
              withCredentials: true
            });
            // console.log("response.data.data", response.data.data)
            setUser1(response.data.data)
            dispatch(setUser(response.data.data))
    
            if(response.data.data.logout){
                dispatch(logout())
                // navigate("/email")
            }
            // console.log("current user Details",response)
          }
        } catch (error) {
            // console.log("error",error)
            // navigate("/email")
        }
      }
    
      useEffect(()=>{
        fetchUserDetails()
      },[])


    useEffect(()=>{
        if(user){
        const socketConnection = io(process.env.REACT_APP_BACKEND_URL,{
          auth : {
            token : localStorage.getItem('support_token')
          },
        })
    
        socketConnection.on('onlineUser',(data)=>{
          // console.log("nnnnnnn", data)
          dispatch(setOnlineUser(data))
        })
    
        dispatch(setSocketConnection(socketConnection))
    
        return ()=>{
          socketConnection.disconnect()
        }
    }
      },[user])


    useEffect(() => {
      const newPeer = new Peer(undefined, {
        // host: '/', port: '3001'
        path: '/',
        secure: true,
        config: {iceServers: [
          { url: 'stun:stun1.l.google.com:19302' },
          { url: 'stun:stun2.l.google.com:19302' },
        ]}
      });
  
      // console.log('newPeer', newPeer )
      
      dispatch(setPeer(newPeer))
    }, [dispatch]);


    
    useEffect(() => {
        // console.log("RRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRr")
        socketConnection?.on('callUserToClient',(data)=>{
            // console.log('callUserToClientttttttttttttttttttttttttttttttttttt' ,  data)
            dispatch(setCall(data))
        })

        return () => socketConnection?.off('callUserToClient');

    },[socketConnection, dispatch])

    useEffect(() => {
        socketConnection?.on('userBusy', (data)=>{
            // dispatch(setCall(null))
            toast.error(`${call.username} is busy!`)
            
        })

        return () => socketConnection?.off('userBusy');
    },[socketConnection, dispatch, call])

}

export default SocketClient