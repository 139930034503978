import "./styles.css";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import AccountBox from "./components/accountBox";


import 'antd/dist/antd.css'

import Modal from "./components/Modal.css/Modal";
import LeftSide from "./components/LeftSide";
import FormContext from "./Contexts/FormContext";
import success from "./assets/success.png"
import SupportEngine from "./SupportEngine";
import CallModal from "./components/ChatExtension/CallModal";
import { useSelector, useDispatch } from 'react-redux';
import SocketClient from './SocketClient';

import { QueryClient, QueryClientProvider } from "@tanstack/react-query";

import i18n from "./translation";

const queryClient = new QueryClient();

export default function App() {

  const AppContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #76b852;
`;

const [isOpen, setIsOpen] = useState(false)
const [upload, setUpload] = useState(false);

const user = useSelector(state => state.user)

useEffect(() => {
  if(upload){
    setIsOpen(true)
  }

}, [upload])


  return (
    <QueryClientProvider client={queryClient}>
    <FormContext.Provider value={{ upload, setUpload }}>
      {user && <SocketClient/>}
      {user?.call && <CallModal />}
    
    <div  style={{ width: '100%', height: '100%', display: 'flex', backgroundColor: '#69bf70'}}>
    {/* backgroundColor: '#76b852' */}
    <div className='signUp-container'  style={{ display: 'flex'}}>
            <LeftSide/>
            <div className="signUp-right signUp-column">
             <AccountBox/>
             </div>
             <SupportEngine />

             <Modal isOpen={isOpen} onClose={() => {setIsOpen(false)}}>
             {i18n.language == 'en' ? <div class="popup" id="popup">
                 <img src={success}/>
                  <h2>Thank You!</h2>
                  <p>Your Report has been successfully submitted. Thanks!</p>
                  <button type="button" 
                  onClick={() => setIsOpen(false)}
                  >OK</button>
              </div>:  <div class="popup" id="popup">
                 <img src={success}/>
                  <h2>እናመሰግናለን!</h2>
                  <p>ጥቆማዎትን ተቀብለናል.  እናመሰግናለን!</p>
                  <button type="button" 
                  onClick={() => setIsOpen(false)}
                  >እሺ</button>
              </div>}
                </Modal>
  
  </div>
  </div>
  </FormContext.Provider>
  </QueryClientProvider>
  );
}
